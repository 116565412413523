
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
} from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import { useStore } from "../store";
import { DatePicker } from "v-calendar";

export default defineComponent({
  name: "BuildYourParty",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    Header,
    Footer,
    DatePicker,
  },
  data: () => {
    return {
      store: useStore(),
      loading: false,
      errors: {},
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DDTHH:mm:ssZZZZ",
      },
      selectAttribute: {
        dot: true,
      },
    };
  },
  computed: {
    getPartyBag(): any {
      return this.store.getters.getPartyBag();
    },
  },
  methods: {
    async confirmParty() {
      this.store.commit("setPartyBag", this.store.state.partyBag);
      if (this.store.state.user === null) {
        this.$router.push({ path: "/register" });
      }
      this.loading = true;
      this.errors = {};
      try {
        const res = await this.store.dispatch("confirmParty");
        // console.log("Confirm Party");
        // console.log(res);
        if (res.status === 200) {
          this.$router.push({ path: "/checkout/" + res.data });
        } else {
          alert("There was an error saving your party. Please try again.");
        }
      } catch (e) {
        console.log("catch error on confirm party");
        console.log(e.response);
        this.errors = e.response.data;
      }
      this.loading = false;
    },
  },
});
